import OgCard from 'src/assets/images/OgCard.png'
import { ROUTES } from 'src/config/routes'

export const SEO_COMMON = {
  image: OgCard,
  site_name: 'artificial.',
  type: 'website',
  twitterName: '@artificiallabs',
}

export const SEO = {
  [ROUTES.homepage()]: {
    title: 'The foundations of modern specialty (re)insurance - artificial.',
    description: 'Build your future placement and underwriting strategy with Artificial\'s pioneering technology.',
    url: 'https://artificial.io/',
    ...SEO_COMMON,
  },
  [ROUTES.platform()]: {
    title: 'The future of underwriting - artificial.',
    description: 'Artificial\'s Smart Underwriting tools enable carriers to deploy underwriting capital to lead and follow opportunities in the most effective way.',
    url: 'https://artificial.io/underwriting-platform',
    ...SEO_COMMON,
  },
  [ROUTES.brokers()]: {
    title: 'The future of risk placement - artificial.',
    description: 'Artificial\'s Smart Placement technology is the only fully-configurable toolkit that creates value through truly digital placement.',
    url: 'https://artificial.io/brokers',
    ...SEO_COMMON,
  },
  [ROUTES.about()]: {
    title: 'About us - artificial.',
    description: 'At Artificial, we’re using the latest technology to give insurers power over their data.',
    url: 'https://artificial.io/about',
    ...SEO_COMMON,
  },
  [ROUTES.careers()]: {
    title: 'Careers - artificial.',
    description: 'At Artificial, we’re using the latest technology to give insurers power over their data.',
    url: 'https://artificial.io/careers',
    ...SEO_COMMON,
  },
  [ROUTES.blog()]: {
    title: 'Blog - artificial.',
    description: 'The latest insurtech news, insights and opinion from Artificial.',
    url: 'https://artificial.io/company/blog',
    ...SEO_COMMON,
  },
  [ROUTES.security()]: {
    title: 'Security - artificial.',
    description: 'Information about Artificial security, monitoring and encryption standards.',
    url: 'https://artificial.io/security',
    ...SEO_COMMON,
  },
  [ROUTES.eula()]: {
    title: 'EULA - artificial.',
    description: 'Information about Artificial EULA and terms of use.',
    url: 'https://artificial.io/eula',
    ...SEO_COMMON,
  },
  [ROUTES.privacyPolicy()]: {
    title: 'Privacy - artificial.',
    description: 'Information about Artificial privacy and data collection standards.',
    url: 'https://artificial.io/privacy',
    ...SEO_COMMON,
  },
  [ROUTES.contact()]: {
    title: 'Contact us - artificial.',
    description: 'Contact us to arrange a demo or speak to our team of experts.',
    url: 'https://artificial.io/contact',
    ...SEO_COMMON,
  },
}
